<template>
  <div
    ref="container"
    class="main-container"
    :class="{ 'main-container--loading': loading }"
  >
    <section>
      <div class="main-container__header">
        <h3 class="h4">{{ $t('parkings.slots.titles.bookable') }}</h3>
      </div>

      <TableSlots
        v-if="current && parkingSlots && slots"
        :head="slotsTypes"
        :headToShow="(slot) => translations[`${slot.translationKey}.name`]"
        :body="parkingSlots"
        :bodyToShow="parkingSlots"
      />
    </section>
    
    <section>
      <div class="main-container__header">
        <h3 class="h4">{{ $t('parkings.slots.titles.fares') }}</h3>
      </div>
        <!-- :head="slots.options" -->
      <TablePrices
        v-if="current && current.packages && slots"
        :key="current.id"
        :head="slotsTypes"
        :headToShow="(slot) => translations[`${slot.translationKey}.name`]"
        :body="current"
        :slotsAvailable="parkingSlots"
      />
    </section>

    <div
      v-show="loading"
      class="main-container-loader"
    >
      <loader />
    </div>
  </div>
</template>

<script>
import TableSlots from '@/components/elements/TableSlots.vue';
import TablePrices from '@/components/elements/TablePrices.vue';
import Loader from '@/components/elements/Loader';

export default {
  name: 'SlotsAndPrices',
  components: {
    TableSlots,
    TablePrices,
    Loader
  },
  beforeMount() {
    const { selected } = this.$store.state.parkings;
    if (selected) {
      this.$store.dispatch('parkings/setParking');
    }
  },
  computed: {
    current() {
      return this.$store.state.parkings.current;
    },
    parkingSlots() {
      return this.$store.state.parkings.current.parkingSlots;
    },
    currencies() {
      return this.$store.state.settings.currencies[0];
    },
    title() {
      return this.$t(this.$route.meta.titleTemplate);
    },
    slotsTypes() {
      const slots = this.$store.state.parkings.current.parkingSlots.map(s => s.slot);
      return slots;
    },
    slots() {
      const slots = this.$store.state.parkings.filters.filter(f => f.id === 'so').shift();
      // const slots = this.$store.state.parkings.current.parkingSlots.map(s => s.slot);
      return slots;
    },
    loading() {
      return this.$store.state.parkings.requests.slotsAndPrices !== 0;
    },
    translations() {
      return this.$store.state.translations.keys;
    }
  },
  watch: {
    current(arr) {
      return arr;
    },
    parkingSlots(arr) {
      return arr;
    },
    loading() {
      if (this.$refs.container) {
        this.$refs.container.scrollTo(0, 0);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    /* overflow: visible; */
    border: 0px solid transparent;
    padding-bottom: 100px;

    .table {
      border-top: 1px solid $lightestgrey;
      border-right: 1px solid $lightestgrey;
    }

    /* margin-bottom: 100px; */
  }

</style>
